.submittable-input_root {
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 2vh;
  background-color: white;
  box-sizing: border-box;
  border: 0.5vh solid black;
}

.submittable-input_input {
  background: transparent;
  width: 60%;
  margin-left: -33%;
  font-size: 100%;
  border: 0;
  height: 100%;
}

.submittable-input_warning {
  position: absolute;
  bottom: -10%;
  color: red;
}

.submittable-input_submit {
  background-color: #ff005d;
  width: 32%;
  height: 100%;
  position: absolute;
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 1vh;
  border-top-right-radius: 1vh;
}
