.game_button-root {
  position: relative;
  height: 100%;
  display: flex;
  width: 95%;
  justify-content: stretch;
  align-items: center;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.game_button-btn {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: calc(var(--vh, 1vh) * 3);
  font-size: calc(var(--vh, 1vh) * 4);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  animation: game-button_appear 0.3s;
  transition: filter 0.3s, transform 0.3s;
  text-align: center;
  padding: calc(var(--vh, 1vh) * 2);
  box-sizing: border-box;
}

.game_button-btn:active {
  filter: brightness(110%);
  transform: scale(0.9);
}

.game_button-btn_primary {
  background: #ff005d;
}

.game_button-btn_secondary {
  background: #526d80;
}

.game_button-disabled {
  filter: grayscale(0.8);
}

@keyframes game-button_appear {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
