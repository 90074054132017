.reconnection_message-reconnecting {
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  background: green;
  color: white;
  text-align: center;
  transition: top 0.4s 0.3s;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
