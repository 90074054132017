.app-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: black;
  color: white;
}

.app-title {
  font-size: calc(var(--vh, 1vh) * 6);
  text-align: center;
  color: white;
  margin-bottom: calc(var(--vh, 1vh) * 1);
  margin-top: calc(var(--vh, 1vh) * 6);
}

.app-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 10);
  width: 100%;
}

.app-reaction_root {
  display: flex;
  font-size: calc(var(--vh, 1vh) * 8);
  justify-content: space-evenly;
  width: 100%;
  margin-top: calc(var(--vh, 1vh) * 4);
}

.app-reaction {
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--vh, 1vh) * 14);
  height: calc(var(--vh, 1vh) * 14);
  border-radius: 50%;
}

.app-reaction:active {
  background: rgba(255, 255, 255, 0.5);
}

.app-voice_chat_overlay_root {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 90%;
  height: 80%;
  background: rgba(255, 255, 255, 1);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  color: black;
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  animation: app-voice_chat_overlay_root_enter 0.6s;
}

@keyframes app-voice_chat_overlay_root_enter {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.app-voice_chat_overlay_title {
  font-size: calc(var(--vh, 1vh) * 4);
  margin-top: calc(var(--vh, 1vh) * 4);
  text-align: center;
}

.app-voice_chat_overlay_subtitle {
  justify-self: flex-start;
  font-size: calc(var(--vh, 1vh) * 3);
  margin-top: calc(var(--vh, 1vh) * 4);
  text-align: center;
}

.app-voice_chat_icon {
  background-image: url('./assets/voice/startCall.webp');
  height: 15vh;
  width: 15vh;
  background-repeat: no-repeat;
  background-size: contain;
}

.app-voice_chat_overlay_btn {
  display: flex;
  height: calc(var(--vh, 1vh) * 10);
  width: 90%;
  margin-bottom: calc(var(--vh, 1vh) * 6);
}
