.mic_header-root {
  z-index: 100;
  display: flex;
  position: absolute;
  left: 4vw;
  top: 2vh;
}

.mic_header-icon_container {
  margin-right: 1.5vw;
  width: 4vh;
  height: 4vh;
  border-radius: 2vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic_header-icon {
  width: 2vh;
  height: 2vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mic_header-join {
  background-image: url('./assets/voice/startCall.webp');
}

.mic_header-leave {
  background-image: url('./assets/voice/stopCall.webp');
}

.mic_header-grab {
  background-image: url('./assets/voice/grabMic.webp');
}

.mic_header-mute {
  background-image: url('./assets/voice/muteMic.webp');
}

.mic_header-unmute {
  background-image: url('./assets/voice/unmuteMic.webp');
}
