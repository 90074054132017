.yard_screen-root {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  width: 100%;
  height: 92%;
}

.yard-tv_pc_container {
  display: flex;
  justify-content: stretch;
  height: calc(var(--vh, 1vh) * 20);
}

.yard_screen-tv {
  margin-left: 10%;
  background-image: url('./assets/tv.webp');
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 7);
  background-size: contain;
  background-repeat: no-repeat;
}

.yard_screen-pc {
  margin-left: 10%;
  background-image: url('./assets/laptop.webp');
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 7);
  background-size: contain;
  background-repeat: no-repeat;
}

.yard-pc_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.yard-tv_download_icon {
  background-image: url('./assets/tvDownload.webp');
  width: calc(var(--vh, 1vh) * 20);
  height: calc(var(--vh, 1vh) * 20);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--vh, 1vh) * 10);
}

.yard_screen-browser {
  background-image: url('./assets/browser.webp');
  width: calc(var(--vh, 1vh) * 31);
  height: calc(var(--vh, 1vh) * 10);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: calc(var(--vh, 1vh) * 10);
}

.yard_screen-pin_example {
  background-image: url('./assets/enterCode.gif');
  width: calc(var(--vh, 1vh) * 35);
  height: calc(var(--vh, 1vh) * 20);
  border-radius: calc(var(--vh, 1vh) * 1);
  background-size: contain;
  background-repeat: no-repeat;
}

.yard_screen-pin {
  background-image: url('./assets/pinCode.webp');
  width: calc(var(--vh, 1vh) * 8);
  height: calc(var(--vh, 1vh) * 8);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: calc(var(--vh, 1vh) * 1);
}

.yard_screen-title {
  font-size: calc(var(--vh, 1vh) * 5);
  margin-top: calc(var(--vh, 1vh) * 2);
  margin-bottom: calc(var(--vh, 1vh) * 2);
  text-align: center;
}
