.root {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}

.rootOpen {
  pointer-events: unset;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.burgerContainer {
  position: absolute;
  right: calc(var(--vh, 1vh) * 2);
  top: calc(var(--vh, 1vh) * 2);
  cursor: pointer;
  transition: transform 0.3s;
  z-index: 1001;
}

.burgerContainerActive {
  transform: rotate(180deg);
}

.title {
  margin-top: calc(var(--vh, 1vh) * 10);
}

.burgerLine {
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 0.7);
  border-radius: calc(var(--vh, 1vh) * 1);
  background: white;
  margin-bottom: calc(var(--vh, 1vh) * 0.5);
  transition: transform 0.3s, width 0.3s;
}

.burgerLineTopActive {
  transform: rotate(-37deg) translateX(calc(var(--vh, 1vh) * -0.7));
  width: calc(var(--vh, 1vh) * 3);
}

.burgerLineBottomActive {
  transform: rotate(37deg) translateX(calc(var(--vh, 1vh) * -0.7));
  width: calc(var(--vh, 1vh) * 3);
}

.body {
  width: calc(var(--vh, 1vh) * 35);
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  transition: right 0.3s;
  background: #ff005d;
  color: white;
  right: calc(var(--vh, 1vh) * -35);
}

.bodyOpen {
  right: 0;
}

.bodyElementsContainer {
  border-top: 3px solid white;
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  padding-top: 2vh;
  margin-left: 2vh;
  margin-right: 2vh;
  color: white;
  font-size: 3vh;
  justify-content: center;
}

.bodyElementIcon {
  width: calc(var(--vh, 1vh) * 3.5);
  height: calc(var(--vh, 1vh) * 3.5);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: calc(var(--vh, 1vh) * 1.2);
}

.bodyElement {
  display: flex;
  margin-bottom: 1vh;
  align-items: center;
  color: white;
  text-decoration: none;
}
