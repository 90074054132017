.yard-and-display-screen_bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: yard-and-display_bg-enter 0.3s;
}

.yard-and-display_body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--vh, 1vh) * 3);
  transition: 0.3s margin-top;
}

.yard-and-display_body-selected {
  margin-top: calc(var(--vh, 1vh) * 15);
}

@keyframes yard-and-display_bg-enter {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * -100));
  }
  100% {
    transform: translateY(0);
  }
}
.yard-and-display-screen_profile-part-root {
  font-size: calc(var(--vh, 1vh) * 4);
}
.yard-and-display-screen_avatar-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.yard-and-display-screen_avatar {
  width: calc(var(--vh, 1vh) * 25);
  height: calc(var(--vh, 1vh) * 25);
  background-repeat: no-repeat;
  background-color: #333333;
  border-radius: 50%;
  background-size: contain;
  position: relative;
}

.yard-and-display-screen_camera-container {
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 7);
  background: #ff005d;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 1px;
}

.yard-and-display-screen_camera {
  width: calc(var(--vh, 1vh) * 3.5);
  height: calc(var(--vh, 1vh) * 3.2);
  background-size: contain;
  background-repeat: no-repeat;
}

.yard-and-display-screen_name {
  overflow: hidden;
  margin-top: calc(var(--vh, 1vh) * 5);
  border-radius: 4px;
  border: 1px solid white;
  width: 90%;
  height: calc(var(--vh, 1vh) * 7);
  position: relative;
  display: flex;
  align-items: center;
}

.yard-and-display-screen_invite {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 20);
  width: 80vw;
  height: calc(var(--vh, 1vh) * 10);
}

.yard-and-display-screen_game {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 5);
  width: 80vw;
  text-align: center;
}

.yard-and-display-screen_buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.yard-and-display-screen_start-button-container {
  height: calc(var(--vh, 1vh) * 16);
  width: 100%;
}

.yard-and-display-popup_body {
  width: calc(var(--vh, 1vh) * 18);
  height: calc(var(--vh, 1vh) * 18);
  background-image: url('../assets/high-five.webp');
  background-repeat: no-repeat;
  background-size: contain;
}
