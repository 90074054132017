.dPad {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.arrowBack {
  border-radius: 50%;
  position: absolute;
  margin-top: calc(var(--vh, 1vh) * -4);
  margin-left: calc(var(--vh, 1vh) * -26);
}

.dPadArrow {
  background: #ff005d;
  text-align: center;
  width: calc(var(--vh, 1vh) * 15);
  height: calc(var(--vh, 1vh) * 15);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s transform, 0.3s filter;
}

.dPadArrow:active {
  filter: brightness(1.2);
  transform: scale(0.8);
}

.dPadArrowIcon {
  background-image: url('../assets/dpad/arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
}

.dPadEnterIcon {
  background-image: url('../assets/dpad/in.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 8);
  height: calc(var(--vh, 1vh) * 8);
}

.dPadRotateIcon {
  background-image: url('../assets/dpad/return.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
}

.leftRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrowRight {
  border-top-right-radius: calc(var(--vh, 1vh) * 1);
  border-bottom-right-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowUp {
  border-top-right-radius: calc(var(--vh, 1vh) * 1.2);
  border-top-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowDown {
  border-bottom-right-radius: calc(var(--vh, 1vh) * 1.2);
  border-bottom-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.arrowLeft {
  border-top-left-radius: calc(var(--vh, 1vh) * 1.2);
  border-bottom-left-radius: calc(var(--vh, 1vh) * 1.2);
}

.mirror {
  margin-top: calc(var(--vh, 1vh) * 4);
  border-radius: calc(var(--vh, 1vh) * 1.2);
  width: 60vw;
}

.mirrorAnimationContainer {
  transform: scale(0);
  animation: mirror-enter 0.6s 0.5s forwards, mirror-shine 4s 2s infinite;
}

@keyframes mirror-shine {
  0% {
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
  100% {
  }
}

@keyframes mirror-enter {
  0% {
  }
  50% {
    transform: scale(1.2);
    filter: brightness(1.2);
  }
  100% {
    transform: scale(1);
  }
}
