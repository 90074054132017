@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

textarea:focus,
input:focus {
  outline: none;
}
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  user-select: none;
}
body {
  margin: 0;
  font-family: 'Roboto';
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}
body * {
  font-family: 'Roboto';
}

.text--title {
  font-size: 96pt;
  line-height: 121pt;
  font-weight: normal;
}

.text--subtitle {
  font-size: 24pt;
  line-height: 24pt;
  font-weight: normal;
}
.text--sm {
  font-size: 24pt;
  line-height: 30pt;
  font-weight: normal;
}

.text--xs {
  font-size: 20pt;
  line-height: 28pt;
  font-weight: normal;
}

code {
  font-family: 'City burn', sans-serif;
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
}

.fill-height {
  height: 100%;
}
.bg-red {
  background-color: red;
}
.bg-highlight {
  background-color: #ffffba;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left !important;
}
.text-end {
  text-align: end;
}
.text-uppercase {
  text-transform: uppercase;
}
.font-weight-normal {
  font-weight: normal;
}
.font-weight-bold {
  font-weight: bold;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.letter-spacing-2 {
  letter-spacing: 0.5em;
}
.text--white {
  color: #ffffff;
}
.text--black {
  color: #000000;
}
.text--red {
  color: #ff0000;
}
.text--orange {
  color: orange;
}
.text--green {
  font-weight: 500;
  color: green;
}
.text--light-green {
  font-weight: 500;
  color: #0dbc5f;
}
.text-h3 {
  font-size: 36px;
}
.text-h4 {
  font-size: 28px;
}
.text-body {
  font-size: 22px;
}
.text-underline {
  text-decoration: underline;
}
.text-strikethrough {
  text-decoration: line-through;
}
.w-100 {
  width: 100%;
}
.pt-1 {
  padding-top: 4px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-3 {
  padding-top: 12px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 20px;
}
.mt-6 {
  margin-top: 24px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-6 {
  margin-bottom: 24px;
}

.ma-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-2 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ma-1 {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
}
.ma-2 {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.ma-3 {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.white--text {
  color: #ffffff !important;
}

.card {
  border-radius: 15px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 1), 0 2px 2px 2px rgba(0, 0, 0, 1), 0 1px 5px 2px rgba(0, 0, 0, 1);*/
  box-shadow: 0 0 0 3px rgb(0, 0, 0);
}
.card-text {
  padding: 16px;
}

.flex {
  flex: 1;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.white-space-nowrap {
  white-space: nowrap;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 50px;
}
/*.button-word {*/
/*  margin: 15px 0;*/
/*  padding: 15px 20px;*/
/*  !*background: #ffcb89;*!*/
/*  background: teal;*/
/*  color: #ffffff;*/
/*  border: 4px solid #ffffff;*/
/*  font-weight: 400;*/
/*  font-size: 26px;*/
/*  opacity: 0.8;*/
/*  border-radius: 5px 20px;*/
/*  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 1px 5px 0 rgba(0, 0, 0, 0.22);*/
/*}*/
/*.button-word.paper-button {*/
/*  background-size: 100% 100%;*/
/*  background-color: transparent;*/
/*  box-shadow: none;*/
/*  border: none;*/
/*  padding: 20px 30px;*/
/*  color: #000000;*/
/*}*/
.button-word {
  margin: 15px 0;
  padding: 15px 20px;
  /*background: #ffcb89;*/
  background: #000;
  color: #ffffff;
  border: 3px solid #ffffff;
  font-weight: 400;
  font-size: 26px;
  opacity: 0.8;
  border-radius: 15px;
  box-shadow: 0 0 0 2px #000000;
}
/*.button-word:hover, .button-word:focus {*/
/*    opacity: 1;*/
/*    background: #0003ff;*/
/*    color: #ffffff;*/
/*}*/

.form-control {
  display: flex;
  flex-direction: row;
}
.muted {
  opacity: 0.6;
}

.input-guess {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  border: 4px solid #ad9eff;
  font-size: 20px;
}
.input-paper,
.input-paper:active,
input-paper:focus {
  background-size: 100% 100%;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 50px 10px;
  font-size: 20px;
  outline: none;
}

.hg-theme-default .hg-button.hg-button-ent {
  background: teal;
  font-weight: bold;
  color: #fff;
}
.hg-theme-default .hg-button.hg-button-clearall {
  background: grey;
  font-weight: bold;
  color: #fff;
}

.border-bottom-1 {
  border-bottom: 3px dotted;
  font-weight: 300;
  min-width: 120px;
}

.badge--icon {
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-shadow--light {
  background: rgba(255, 255, 255, 0.6);
}

.col-6 {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.card--white {
  background: #ffffff;
  border-radius: 6px;
  min-width: 85%;
  text-align: center;
  /*box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);*/
  /*box-shadow: 0 0 0 3px #000;*/
}
.card--white.active {
  background: transparent;
  color: #ffffff;
}
.card--white--inner {
  border-radius: 6px;
  padding: 18px;
  margin: 8px;
  /*box-shadow: 0 0 0 3px #000;*/
}
.card--white.dense .card--white--inner {
  padding: 6px;
}
.card--white.active .card--white--inner {
  /*box-shadow: 0 0 0 3px #ffffff;*/
}

/* This is to change the keyboard button sizes */
/*.hg-theme-default .hg-button {*/
/*  padding: 3vh 5px;*/
/*}*/

@keyframes bounce {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-5px) translateX(-5px);
  }
}
.bounce {
  animation: bounce 1.5s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(-5px) scale(1.05);
  }
}
.pulse {
  animation: pulse 1.1s infinite alternate;
}

.transition--slideUp {
  position: absolute;
  bottom: -2000px;
  transition: bottom 0.3s;
}
.transition--slideUp.transition--active {
  bottom: 0;
}

.transition--fadeIn {
  opacity: 0;
  transition: opacity 0.3s;
}
.transition--fadeIn.transition--active {
  opacity: 1;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animation--blink {
  animation: blink 0.5s infinite alternate;
}
